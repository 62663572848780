<template>
	<div>
		<div v-title data-title="多对多SaaS管理平台"></div>
		<nav class="page_head_nav">
			<p>企业用户管理</p>
			<i>/</i>
			<p>企业用户列表</p>
		</nav>
		
		<div class="head_search_wrap">
			
			<div class="search_one_line">
				<div class="left">
					<el-form label-width="130px" @submit.native.prevent>
						<el-form-item label="企业名称/账号:">
							<el-input class="input_medi" v-model="keyword" @keyup.enter.native="searchFn" clearable placeholder="输入企业名称/账号搜索"></el-input>
						</el-form-item>
						<el-form-item label="状态：">
							<el-select v-model="type" placeholder="全部状态">
								<el-option label="激活" value="1"></el-option>
								<el-option label="冻结" value="0"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
				<div class="right">
					<p class="reset" @click="resetFn">重置</p>
					<p class="search" @click="searchFn">查询</p>
				</div>
			</div>
			
		</div>
		
		<div class="page_table">
			<div class="page_table_title">
				<div class="left">
					<p>企业用户列表</p>
				</div>
				<div class="right">
					<span class="span1" @click="addFn">新增</span>
				</div>
			</div>
			<el-table :data="listData" class="tb-edit" style="width: 100%" highlight-current-row>
				<el-table-column prop="user_sn" label="企业编码" align="left"></el-table-column>
				<el-table-column prop="company" label="企业名称" align="left"></el-table-column>
				<el-table-column prop="mobile" label="企业账号" align="center"></el-table-column>
				<el-table-column prop="agent_name" label="归属代理商" align="center"></el-table-column>
				<el-table-column prop="sum_order_amount" label="累计消费(元)" align="center"></el-table-column>
				<el-table-column prop="count_order" label="累计订单数" align="center"></el-table-column>
				<el-table-column prop="" label="状态" align="center">
					<template slot-scope="scope">
						<div v-if="scope.row.status == '1'">
							<p class="is_sale_1">激活</p>
						</div>
						<div v-else>
							<p class="is_sale_0">冻结</p>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="140px">
					<template slot-scope="scope">
						<div class="operation">
							<span @click="modifyFn(scope.row)">修改</span>
							<span @click="goInfoFn(scope.row)">认证信息</span>
						</div>
					</template>
				</el-table-column>
			</el-table>
			
			<div class="block">
				<el-pagination @current-change="currentChange" :current-page="currentPage1" :total="total">
				</el-pagination>
			</div>
			
		</div>
		
		
		
	</div>
</template>

<script>
	
	export default {
		name: 'enterpriseUserList',
		data(){
			return{
				listData: [], // 列表数据
				total: 0, //总页数
				currentPage1: 1, //当前页码
				keyword: '', // 关键字搜索
				type: '',  // 状态
			}
		},
		components: {
			
		},
		created() {
			// 获取企业用户列表
			this.getEnterpriseUserList();
		},
		methods: {
			// 重置
			resetFn() {
				this.currentPage1 = 1;
				this.keyword = '';
				this.type = '';
				this.phone = '';
				this.getEnterpriseUserList();
			},
			
			// 搜索
			searchFn() {
				this.currentPage1 = 1;
				this.getEnterpriseUserList();
			},
			
			// 获取企业用户列表
			getEnterpriseUserList(){
				this.$sendHttp({
					method: 'POST',
					url: `${process.env.VUE_APP_SAAS}customuser/list`,
					data: {
						page: this.currentPage1,
						keyword: this.keyword,
						status: this.type
					}
				}).then( res => {
					if(res.code == '0'){
						this.total = res.count;
						this.listData = res.data;
					}
				})
			},
			
			// 分页器函数
			currentChange(val) {
				this.currentPage1 = val;
			},
			
			// 修改
			modifyFn(row) {
				this.$router.push({
					path: '/enterpriseUserEdit',
					query:{
						id: row.id,
					}
				})
			},
			
			// 新增
			addFn(){
				this.$router.push('/enterpriseUserEdit');
			},
			
			// 认证信息
			goInfoFn(row){
				this.$router.push({
					path: '/authenticationInfo',
					query:{
						id: row.id,
					}
				})
			},
			
		}
	}
</script>
<style scoped lang="less">
	
</style>
